@import 'app/common/styles/background-colors.module.scss';

.infoBlock {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0.625rem;
  padding-inline-start: 0.5rem;
  border-radius: 0.5rem;

  .infoWrapper {
    flex-basis: 100%;
    vertical-align: middle;
    line-height: 1.25rem;
    -moz-hyphens: none;
    hyphens: none;
    word-wrap: break-word;
  }

  .icon {
    margin-inline-end: 0.5rem;
    align-self: flex-start;
  }

  &.fullWidth {
    width: 100%;
  }

  &.def {
    background-color: $bg-island-inner;
  }

  &.success {
    background-color: $bg-success-light;
  }

  &.warning {
    background-color: $bg-warning-light;
  }

  &.error {
    background-color: $bg-erorr-light;
  }
}
